import{
    Session,
    Conference,
    ParticipantsGrid,
    ScreenSharingPresentationBox,
    ScreenSharingActionBar,
    useScreenSharing,
    Space,
    useRecording,
    useAudio,
    useVideo,
    useSession,
    useConference,
    useParticipants,
    RecordingStatus,
    ShareStatus,
    RecordingActionBar,
  } from '@dolbyio/comms-uikit-react';
  import VoxeetSDK from '@voxeet/voxeet-web-sdk';
  import Dropdown from "react-bootstrap/Dropdown";
  import React, { useEffect, useState,useCallback } from 'react';
  import {
    RecordingActionBarTexts,
    fallbackButtonText,
    fallbackText,
    screenSharingActionBarTexts,
    buttonContainerStyle,
    maximizedSharedScreen,
    minmizedSharedScreen,
    btnMax,
    btnMax2,
    btnMax3,
    btnMax4,
    maximizedlayout,
    maximizedlayout2,
    maxlayoutstyle,
    minlayoutstyle,
    localTextInGrid,
  } from '../../utils/constant';
  import EnabledAudioIcon from './icons/enabledAudioIcon';
  import DisabledAudioIcon from './icons/disabledAudioIcon';
  import EnabledVideoIcon from './icons/enabledVideoIcon';
  import DisabledVideoIcon from './icons/disabledVideoIcon';
  import { Tooltip as ReactTooltip } from "react-tooltip";
  import Essenitals from './participantsView';
  import { useUnityContext } from "react-unity-webgl";
  import {UNITY_URLS} from '../../utils/config';
  import axios from 'axios';


const Content = (props) => {
   
    const {addEventListener, removeEventListener , sendMessage} = useUnityContext(UNITY_URLS);
    const [isVideo2, setVideo2] = useState(false);
    const [role, setrole] = useState('');
    const [isAudio2, setAudio2] = useState('');
    const [conferenceId, setConferenceId] = useState();
    const [isMaxScreen, setMaxScreen] = useState(true);
    const [isList, setShowList] = useState(false);
    const { participant } = useSession();
    const { conference, leaveConference } = useConference();
    const { participants ,participantsStatus} = useParticipants();
    const { p1 ,setp1} = useState();
    var { isSpeaking } = {}
    const [langsused, setlangsused] = useState({id:false});
    const [auidoOpt, setAudioOpt] = useState({id:false});
    const [webCamOpt, setWebCamOpt] = useState({id:false});
    const [shareScreenOpt, setShareScreenOpt] = useState({id:false});
    const [intervalId, setIntervalId] = useState(null);
    const { closeSession } = useSession();
    const { toggleAudio,isAudio } = useAudio(false);
    const { toggleVideo , isVideo} = useVideo();
    const {firstPresenter, owners, status, isLocalUserPresentationOwner, isPresentationModeActive,startScreenShare,stopScreenShare } = useScreenSharing();
    const { status: recordingStatus, isLocalUserRecordingOwner } = useRecording();
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [CloseSessionModal, setCloseSession] = useState(false)
    const [counter, setCounter] = useState(5);


    //Transcript Hooks
    // const { transcript, finalTranscript, browserSupportsSpeechRecognition,resetTranscript,listening } = useSpeechRecognition();
    // const [showText, setShowText] = useState(false);
    // const [isCaption, setShowCaption] = useState(false);
    // const [captionON,setcaptionON] = useState(false)
    // const [ActiveOption,setActiveOption] = useState(false)
    // const [translatedText, setTranslatedText] = useState('');
    // const [longText,setLongText] = useState('');
    //









   
   // const socket = io('https://socketservice.holofair.io');
   const isPresentationActive =
   status === ShareStatus.Active || (isLocalUserPresentationOwner && isPresentationModeActive);
   const setInsert = useCallback( async (id) =>{
    console.log("Inserting...")
    //axios.post('http://localhost/api.holofair.net-master/eventFiles/dolby.php', {uid:participant?.id,role:props.role})
    axios.post('https://socketservice.holofair.io/api/insert/', {uid:id,audio:props.role === "listener"?0:2,video:props.role === "listener"?0:2,sharescreen:props.role === "listener"?0:2,role:props.role})
   // axios.post('https://horizontech-iq.com/testapi/eventFiles/dolby.php', {uid:participant?.id,role:props.role})
    .then((response) => {
      console.log('Response:', response.data);
     // setShow(participant.id)
     //setShow()
    })
    .catch((error) => {
      console.error('Error:', error);
    });

  },[props.role])

  const endCall =  useCallback( async () => {
     if(status === 'active' && isPresentationModeActive){
       alert("Pleae stop sharing before ending the call")
     }else{
       localStorage.setItem('callstatus','')
       console.log(`Leaving the conference`)
     try {
      await VoxeetSDK.conference.leave();
      await VoxeetSDK.session.close();
      console.log('Successfully left the session');
      props.videoTag("App","ToggleWebGLInputAutoCapturing",0)
    } catch (error) {
      console.error('Error leaving the session:', error);
    }
       props.update(false)
       
     }
   },[isPresentationModeActive, props, status]);


   const micUnmic = useCallback(() => {
    console.log('mic')
    if(isAudio){
      VoxeetSDK.audio.local.start()
    }else{
      VoxeetSDK.audio.local.stop()
    }

    toggleAudio()
  },[isAudio, toggleAudio])

   
 


   const leaveDolbyfunc = useCallback(()=>{
    console.log("Leaving now")
    props.videoTag("App","ToggleWebGLInputAutoCapturing",1)
     localStorage.setItem('leaveSession','yes')
     setCloseSession(true)
     setTimeout(() => {
       endCall();
       setCloseSession(false)
       localStorage.setItem('leaveSession','no')
      // localStorage.setItem('startSession','no')
     }, 5000);
    
     if(!isAudio)
       micUnmic()

  },[endCall, isAudio, micUnmic, props])

    useEffect(() => {
       const handler = (participant) => {
        console.log(participant.info.name, 'status:', participant.status);
        console.log(participant.info.name, 'has audio enabled:', participant.audioTransmitting);
        console.log(participant.id)
  
      if(participant.info.name === props.usern){

        } 
        if(isPresentationActive){
          //console.log('Yes, 337')
          var videoElement = document.querySelector('.PresentationBox-module_container__muYQW video');
          videoElement.id = (participant)?participant.id:'vid337_presentation';
          props.videoTag('VideoIDListener', 'SetVideoTag',  videoElement.id);
        }
        
      };

    //   console.log(props.usern + '' + props.mname + ' ' + props.id)
    //   console.log(props.id)
    //   console.log(role)

      if(localStorage.getItem('ppid') === ''){
        participants.map((p,index) => { //// setInsert(p.id);
          return (p.info.name === props.usern) ? localStorage.setItem('ppid',p.id)  : ''
        });
       
      }

      if(CloseSessionModal === true && counter > 0){
         const timer = setTimeout(() => setCounter(counter - 1), 1000);
         return () => clearTimeout(timer)
         }
      
      // register the handler for 'participantUpdated' event
      VoxeetSDK.conference.on('participantUpdated', handler);
      
      addEventListener("leaveDolby",leaveDolbyfunc);
      window.addEventListener('beforeunload', leaveDolbyfunc);
     
      return () => {
        // unregister the handler when the component is unloaded
        VoxeetSDK.conference.removeListener('participantUpdated', handler);
        // socket.off('database_updated');
        // socket.off('receive message');
        // socket.disconnect();
       
        //
        removeEventListener("leaveDolby",leaveDolbyfunc);
        window.removeEventListener('beforeunload', leaveDolbyfunc);
      };
    }, [CloseSessionModal, addEventListener, conferenceId, counter, isPresentationActive, leaveDolbyfunc, participants, participantsStatus, props, props.id, props.mname, props.usern, removeEventListener, role, setInsert]);

    function g(){
      console.log("yes")
      // alert("Yes")
      //setVideo(true)
    }

      const isRecordingActive = isLocalUserRecordingOwner || recordingStatus === RecordingStatus.Active;

      async function startScreenSharing () {
        try {
    
        if(isPresentationModeActive) stopScreenShare()
        if(status === 'active' && !isPresentationModeActive)
        //console.log('Already there');
        alert('Someone is Already Presenting')
        else
        startScreenShare();
        // alert('Already running') 
        } catch (error) {
          console.error('Error accessing screen media stream:', error);
        }
      };

      async function ScreenView() {
        setMaxScreen(!isMaxScreen)
      }

     

      async function showParticipants(){
        setShowList(!isList)
      }

      const onCloseSession = () => {
        setCloseSession(false)
      }

     // Controls

     const sendMessage2 = (msg) => {
      // socket.emit('send message', msg);
      // setMessages((prevMessages) => [...prevMessages, `You: ${msg}`]);
      if (msg.trim()) {
       // socket.emit('send message', msg);
        setMessage('');
      }
    };


     const setShow = async (id) => {
      //  axios.get('http://localhost/api.holofair.net-master/eventFiles/fetch.php?uid='+participant?.id)
      axios.get('https://socketservice.holofair.io/api/fetch?uid='+participant?.id)
     // await  axios.get('https://horizontech-iq.com/testapi/eventFiles/fetch.php?uid='+participant?.id)
        .then((response) => {
          
          // console.log('Response:', response.data.records[0].uid);
          console.log('Response:', response.data.message[0].uid);
          // if(response.data.records[0].audio === 1){
          if(response.data.message[0].is_audio === 1){
            setAudio2(participant?.id)
           // VoxeetSDK.audio.local.stop(id);
            VoxeetSDK.audio.local.stop(id);
           // VoxeetSDK.audio.local.start(id);
            //micUnmic()
            //toggleAudio()
            
          }
          // if(response.data.records[0].audio === 0){
          if(response.data.message[0].is_audio === 0){
            setAudio2('')
            VoxeetSDK.audio.local.stop(id);
          //  micUnmic() 
          
          }
        })
        .catch((error) => { 
          console.error('Error:', error);
        });

      }

     const webCamSwitcher = async (id) => { 
      
      setWebCamOpt(prevValues => ({
        ...prevValues,
        [id]: !prevValues[id] 
      }));

      if((webCamOpt[id] === undefined && id !== webCamOpt) || (webCamOpt[id] === false && id !== !webCamOpt))
      setActionVideo(id,1)
      else
      setActionVideo(id,0)

    }

    const shareScreenSwitcher = async (id) => {
    
      setShareScreenOpt(prevValues => ({
        ...prevValues,
        [id]: !prevValues[id] 
      }));

      if((shareScreenOpt[id] === undefined && id !== shareScreenOpt) || (shareScreenOpt[id] === false && id !== !shareScreenOpt))
      setActionSS(id,1)
      else
      setActionSS(id,0)

    }

    const audioSwitcher = async (id) => {
      console.log(id)
      setAudioOpt(prevValues => ({
        ...prevValues,
        [id]: !prevValues[id] 
      }));
     
      if((auidoOpt[id] === undefined && id !== isAudio2) || (auidoOpt[id] === false && id !== !isAudio2))
      setAction(id,1)
      else
      setAction(id,0)
    }

    const setAction = async (id,val) =>{
      console.log('Updating....',val)
       axios.put('https://socketservice.holofair.io/api/update', {audio:val,uid:id})
    // axios.post('https://horizontech-iq.com/testapi/eventFiles/update_audio.php', {uid:id,audio:val})
      .then((response) => {
        console.log('Response:', response.data);
       // if(val === 1)
       //    VoxeetSDK.audio.remote.start(id);
       //   else
       //    VoxeetSDK.audio.remote.stop(id);
   
        setShow()
       // setShow(participant.id)
      })
      .catch((error) => {
        console.error('Error:', error);
      });

     }

     

     const setActionVideo = async (id,val) =>{
       console.log('Updating....',val)
        axios.put('https://socketservice.holofair.io/api/videoctrl', {video:val,uid:id})
   
       .then((response) => {
         console.log('Response:', response.data);
         setShow()
       })
       .catch((error) => {
         console.error('Error:', error);
       });

      }

      const setActionSS = async (id,val) =>{
       console.log('Updating....',val)
        axios.put('https://socketservice.holofair.io/api/ssctrl', {sharescreen:val,uid:id})
   
       .then((response) => {
         console.log('Response:', response.data);
         setShow()
       })
       .catch((error) => {
         console.error('Error:', error);
       });

      }


    // End Controls


      async function videoUnvideo(){

        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        stream.getTracks().forEach(track => track.stop());

      // setVideo2(!isVideo2)
       if(!isVideo2){
       VoxeetSDK.video.local.start()
       setVideo2(true)
       }
       else{
       VoxeetSDK.video.local.stop()
       // toggleVideo()
       setVideo2(false)
       }
      }


      return (
        <div className="App" style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'center',
        backgroundColor: '#14141A',
      
        left:isMaxScreen?'2%':'32%'}}>


        {CloseSessionModal &&
          <div className="custom-alert-overlay">
            <div className="custom-alert">
              <p>You are leaving the call in {counter}s</p>
              <button className='buttonAlert' onClick={onCloseSession}>Close</button>
            </div>
         </div>
         }

         
          {Essenitals({
                      participants,
                      isMaxScreen,
                      maximizedlayout,
                      maximizedlayout2,
                      btnMax,
                      btnMax2,
                      btnMax3,
                      btnMax4,
                      maxlayoutstyle,
                      minlayoutstyle,
                      isPresentationActive,
                      minmizedSharedScreen,
                      maximizedSharedScreen
                    })}



           {/* Min Share Screen Start */}
          { !isMaxScreen && isPresentationActive &&
          <div>
           <Space style={{BorderRadius:"11px",display: "block !important", position:"absolute", right:"0px", top:"207px", height: 'auto', flexGrow: 0,width:"17vw",background:"rgb(1 18 38 / 85%)",overflow:"hidden",overflowY:"hidden", maxHeight:"367px" }}> 
          <div style={{position:"relative",paddingTop:"37px"}}>
          <button  onClick={ScreenView} className='dolbyicon sidebtn'>
          <img  src="assets/Maximize.svg" alt="" /></button>
          </div>
            <ScreenSharingPresentationBox fallbackText={fallbackText} fallbackButtonText={fallbackButtonText} />
           <Space>
            <ParticipantsGrid  localText={localTextInGrid} additionalContainerStyle={{  }} />
          </Space>

          </Space>

          </div>

            }
           
          {isVideo2 && !isMaxScreen && !isPresentationActive &&
          <Space style={{borderRadius:"11px",display: "block !important", position:"absolute", right:"0px", top:"207px", height: 'auto', flexGrow: 0,width:"17vw",background:"rgb(1 18 38 / 85%)",overflow:"hidden",overflowY:"scroll", maxHeight:"405px" }}>
            <div style={{position:"relative",paddingTop:"12px"}}>
            <button  onClick={ScreenView} className='dolbyicon sidebtn'>
             <img  src="assets/Maximize.svg" alt="" /></button>
            </div>
              <ParticipantsGrid  localText={localTextInGrid} additionalContainerStyle={{  }} />
              {/* height:395 */}
          </Space>

          }

            {/* Min Share Screen End */}

            {/* Display Video Max Start */} 
    
           {isVideo2 && isMaxScreen && !isPresentationActive &&
          <Space style={{paddingLeft:"15px",position:"absolute", top:"6%",left:"2%",paddingRight:"15px",borderRadius:"11px", right:"0px", height: 'auto',width:"135vh", margin:'auto',background:"rgb(1 18 38 / 85%)",overflow:"hidden" }}>
            <div style={{position:'relative',height:"610px",padding:"15px"}}>
            <button  onClick={ScreenView} className='dolbyicon sidebar_max'>
             <img width='30px' src="assets/Minimize.svg" alt="" /></button>
            <ParticipantsGrid  localText={localTextInGrid} additionalContainerStyle={{ height: 680 }} />
            </div>
          </Space>
          
          }

           {/* Display Video Max Start */} 

            <Session participantInfo={{name:props.usern}}>
                    {!props.id ? (   
                    <div style={buttonContainerStyle}>  
                    </div>
                    ) : (
            
    <Conference id={props.id} >
               
           {isList && props.role === 'speaker' &&
                
                <Space fw style={{zIndex:'999', position:"absolute", top:"55px", left:"0px",right:"0px",height: 650, overflowY: 'scroll',width:"50%",margin:"0 auto", borderRadius:"10px", overflow:"hidden", background:"rgb(1 18 38)"}}>
                  <h3 className='ParticipantsText'>Participants ({participants.length})</h3>
              
               {participants.map((p,index) => {
              
                 return  <div style={{color: "#fff",
                  paddingLeft: "14px",paddingRight:"5px",display:"flex",justifyContent:"space-between"}}>
                    <div style={{textAlign:"left"}}>{p.info.name} </div>
                    
                 <div style={{textAlign:"right",width:!participantsStatus[p.id].isLocal?"70%":"70%"}}>
                  {/* <button onClick={() => remoteAudio(participant.id)}>Action</button> */}

            {!participantsStatus[p.id].isLocal && 
                <Dropdown style={{display:"inline-block"}}>

                      <Dropdown.Toggle>
                      <svg fill="#ffffff"  width="21px"  id="Capa_1"  
                        viewBox="0 0 32.055 32.055" >
                        <path d="M3.968,12.061C1.775,12.061,0,13.835,0,16.027c0,2.192,1.773,3.967,3.968,3.967c2.189,0,3.966-1.772,3.966-3.967
                          C7.934,13.835,6.157,12.061,3.968,12.061z M16.233,12.061c-2.188,0-3.968,1.773-3.968,3.965c0,2.192,1.778,3.967,3.968,3.967
                          s3.97-1.772,3.97-3.967C20.201,13.835,18.423,12.061,16.233,12.061z M28.09,12.061c-2.192,0-3.969,1.774-3.969,3.967
                          c0,2.19,1.774,3.965,3.969,3.965c2.188,0,3.965-1.772,3.965-3.965S30.278,12.061,28.09,12.061z"/>
                      </svg>
                    </Dropdown.Toggle> 
                    <Dropdown.Menu size="sm" title="">
                      <Dropdown.Item><button className='dropdown-toggle btn btn-primary' onClick={() => audioSwitcher(p.id)}>{!auidoOpt[p.id]?'Enable Audio':'Disable Audio'}</button></Dropdown.Item>
                      <Dropdown.Item><button className='dropdown-toggle btn btn-primary' onClick={() => webCamSwitcher(p.id)}>{!webCamOpt[p.id]?'Enable webcam':'Disable webcam'}</button></Dropdown.Item>
                      <Dropdown.Item><button className='dropdown-toggle btn btn-primary' onClick={() => shareScreenSwitcher(p.id)}>{!shareScreenOpt[p.id]?'Enable sharescreen':'Disable sharescreen'}</button></Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
               }
              
                  {p.audioTransmitting === true &&
                   
                          <svg
                          style={{marginRight:"15px",width:"21px"}}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          data-testid={props.testID}
                          fill={props.fill || 'currentColor'}
                          >
                          <path
                            fill={props.fill || 'currentColor'}
                            d="M12 16.5a4.505 4.505 0 0 0 4.5-4.5V6a4.5 4.5 0 1 0-9 0v6a4.505 4.505 0 0 0 4.5 4.5ZM9 6a3 3 0 0 1 6 0v6a3 3 0 0 1-6 0V6Zm10.454 6.832a7.484 7.484 0 0 1-6.704 6.63v2.288a.75.75 0 1 1-1.5 0v-2.288a7.485 7.485 0 0 1-6.704-6.63.75.75 0 0 1 1.49-.164 6 6 0 0 0 11.927 0 .75.75 0 0 1 1.491.164Z"
                          />
                          </svg>
               }
                 {p.videoTransmitting === true &&
                      
                          <svg
                          style={{width:"21px"}}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          data-testid={props.testID}
                          fill={props.fill || 'currentColor'}
                          >
                          <path
                            fill={props.fill || 'currentColor'}
                            d="M22.877 6.851a.75.75 0 0 0-.75-.002L18 9.208v-.583a3.754 3.754 0 0 0-3.75-3.75h-12a1.502 1.502 0 0 0-1.5 1.5v9a3.754 3.754 0 0 0 3.75 3.75h12a1.502 1.502 0 0 0 1.5-1.5v-2.833l4.128 2.36a.75.75 0 0 0 1.122-.652v-9a.75.75 0 0 0-.373-.649ZM16.5 17.625h-12a2.253 2.253 0 0 1-2.25-2.25v-9h12a2.253 2.253 0 0 1 2.25 2.25v9Zm5.25-2.417L18 13.065v-2.13l3.75-2.143v6.416Z"
                          />
                          </svg>
                    
                   }

                  {p.audioTransmitting === false && 
                  <svg
                      style={{marginRight:"15px",width:"21px"}}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      data-testid={props.testID}
                      fill={props.fill || 'currentColor'}
                    >
                      <path
                        fill={props.fill || 'currentColor'}
                        d="m20.055 19.745-15-16.5a.75.75 0 1 0-1.11 1.01L7.5 8.165V12a4.5 4.5 0 0 0 6.93 3.787l1.019 1.122a5.999 5.999 0 0 1-9.412-4.241.75.75 0 0 0-1.491.164 7.485 7.485 0 0 0 6.704 6.63v2.288a.75.75 0 0 0 1.5 0v-2.288a7.473 7.473 0 0 0 3.714-1.436l2.481 2.729a.75.75 0 1 0 1.11-1.01ZM12 15a3.004 3.004 0 0 1-3-3V9.815l4.399 4.839A2.994 2.994 0 0 1 12 15ZM8.17 3.635A4.5 4.5 0 0 1 16.5 6v5.665a.75.75 0 1 1-1.5 0V6a3 3 0 0 0-5.553-1.576.75.75 0 1 1-1.276-.789Zm9.42 10.55a5.97 5.97 0 0 0 .373-1.517.75.75 0 0 1 1.491.164 7.467 7.467 0 0 1-.467 1.899.75.75 0 0 1-1.397-.547Z"
                      />
                  </svg>
               }
                {p.videoTransmitting === false &&
                  <svg
                    style={{width:"21px"}}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    data-testid={props.testID}
                    fill={props.fill || 'currentColor'}
                  >
                    <path
                      fill={props.fill || 'currentColor'}
                      d="M22.877 6.851a.75.75 0 0 0-.75-.002L18 9.208v-.583a3.754 3.754 0 0 0-3.75-3.75h-3.855a.75.75 0 0 0 0 1.5h3.855a2.253 2.253 0 0 1 2.25 2.25V13.5a.75.75 0 0 0 .023.183l.006.022c.016.057.04.113.069.165l.011.018c.012.02.025.039.038.057l.015.02a.798.798 0 0 0 .058.065c0 .002.002.003.003.004a.773.773 0 0 0 .144.11l.01.007 5.25 3a.75.75 0 0 0 1.123-.651v-9a.75.75 0 0 0-.373-.649Zm-1.127 8.357L18 13.065v-2.13l3.75-2.143v6.416ZM3.828 1.745a.75.75 0 1 0-1.11 1.01l1.927 2.12H2.25a1.502 1.502 0 0 0-1.5 1.5v9a3.754 3.754 0 0 0 3.75 3.75h12c.306 0 .605-.094.855-.27l3.09 3.4a.75.75 0 0 0 1.11-1.01L3.828 1.745Zm.672 15.88a2.253 2.253 0 0 1-2.25-2.25v-9h3.76l10.226 11.25H4.5Z"
                    />
                  </svg>
                }
              </div>
        
                  <hr 
                  style={{marginRight: "21px",
                    border:"1px solid #94949457"}}
                  />
                  
                  </div>
                 
                })
              } 

                </Space>
               }

              {/* {Speaker List} */}

              {isList && props.role !== 'speaker' &&
                
                <Space fw style={{zIndex:'999', position:"absolute", top:"55px", left:"0px",right:"0px",height: 650, overflowY: 'scroll',width:"50%",margin:"0 auto", borderRadius:"10px", overflow:"hidden", background:"rgb(1 18 38)"}}>
                  <h3 className='ParticipantsText'>Participants ({participants.length})</h3>
                 
                
               
               {participants.map((p,index) => {
                 console.log(index)



                 
                 return  <div style={{color: "#fff",
                 paddingLeft: "14px",paddingRight:"5px",display:"flex",justifyContent:"space-between"}}>
                    <div style={{textAlign:"left"}}>{p.info.name} </div>
                   
               
        <div style={{textAlign:"right",width:"70%"}}>

                  {p.audioTransmitting === true &&
                   
                  //  <svg
                  //  style={{marginRight:"15px",width:"21px"}}
                  //  viewBox="0 0 24 24"
                  //  xmlns="http://www.w3.org/2000/svg"
                  //  data-testid={props.testID}
                  //  fill={props.fill || 'currentColor'}
                  //  >
                  //  <path
                  //    fill={props.fill || 'currentColor'}
                  //    d="M12 16.5a4.505 4.505 0 0 0 4.5-4.5V6a4.5 4.5 0 1 0-9 0v6a4.505 4.505 0 0 0 4.5 4.5ZM9 6a3 3 0 0 1 6 0v6a3 3 0 0 1-6 0V6Zm10.454 6.832a7.484 7.484 0 0 1-6.704 6.63v2.288a.75.75 0 1 1-1.5 0v-2.288a7.485 7.485 0 0 1-6.704-6.63.75.75 0 0 1 1.49-.164 6 6 0 0 0 11.927 0 .75.75 0 0 1 1.491.164Z"
                  //  />
                  //  </svg>
                  <EnabledAudioIcon fill="#ffffff" testID="custom-svg-1" />
        }
          {p.videoTransmitting === true &&
               
                  //  <svg
                  //  style={{width:"21px"}}
                  //  viewBox="0 0 24 24"
                  //  xmlns="http://www.w3.org/2000/svg"
                  //  data-testid={props.testID}
                  //  fill={props.fill || 'currentColor'}
                  //  >
                  //  <path
                  //    fill={props.fill || 'currentColor'}
                  //    d="M22.877 6.851a.75.75 0 0 0-.75-.002L18 9.208v-.583a3.754 3.754 0 0 0-3.75-3.75h-12a1.502 1.502 0 0 0-1.5 1.5v9a3.754 3.754 0 0 0 3.75 3.75h12a1.502 1.502 0 0 0 1.5-1.5v-2.833l4.128 2.36a.75.75 0 0 0 1.122-.652v-9a.75.75 0 0 0-.373-.649ZM16.5 17.625h-12a2.253 2.253 0 0 1-2.25-2.25v-9h12a2.253 2.253 0 0 1 2.25 2.25v9Zm5.25-2.417L18 13.065v-2.13l3.75-2.143v6.416Z"
                  //  />
                  //  </svg>

                  <EnabledVideoIcon fill="#ffffff" testID="custom-svg-2" />
             
           
            }

           {p.audioTransmitting === false && 
          //  <svg
          //      style={{marginRight:"15px",width:"21px"}}
          //      viewBox="0 0 24 24"
          //      xmlns="http://www.w3.org/2000/svg"
          //      data-testid={props.testID}
          //      fill={props.fill || 'currentColor'}
          //    >
          //      <path
          //        fill={props.fill || 'currentColor'}
          //        d="m20.055 19.745-15-16.5a.75.75 0 1 0-1.11 1.01L7.5 8.165V12a4.5 4.5 0 0 0 6.93 3.787l1.019 1.122a5.999 5.999 0 0 1-9.412-4.241.75.75 0 0 0-1.491.164 7.485 7.485 0 0 0 6.704 6.63v2.288a.75.75 0 0 0 1.5 0v-2.288a7.473 7.473 0 0 0 3.714-1.436l2.481 2.729a.75.75 0 1 0 1.11-1.01ZM12 15a3.004 3.004 0 0 1-3-3V9.815l4.399 4.839A2.994 2.994 0 0 1 12 15ZM8.17 3.635A4.5 4.5 0 0 1 16.5 6v5.665a.75.75 0 1 1-1.5 0V6a3 3 0 0 0-5.553-1.576.75.75 0 1 1-1.276-.789Zm9.42 10.55a5.97 5.97 0 0 0 .373-1.517.75.75 0 0 1 1.491.164 7.467 7.467 0 0 1-.467 1.899.75.75 0 0 1-1.397-.547Z"
          //      />
          //  </svg>

          <DisabledAudioIcon fill="#ffffff" testID="custom-svg-3" />
        }
         {p.videoTransmitting === false &&
          //  <svg
          //    style={{width:"21px"}}
          //    viewBox="0 0 24 24"
          //    xmlns="http://www.w3.org/2000/svg"
          //    data-testid={props.testID}
          //    fill={props.fill || 'currentColor'}
          //  >
          //    <path
          //      fill={props.fill || 'currentColor'}
          //      d="M22.877 6.851a.75.75 0 0 0-.75-.002L18 9.208v-.583a3.754 3.754 0 0 0-3.75-3.75h-3.855a.75.75 0 0 0 0 1.5h3.855a2.253 2.253 0 0 1 2.25 2.25V13.5a.75.75 0 0 0 .023.183l.006.022c.016.057.04.113.069.165l.011.018c.012.02.025.039.038.057l.015.02a.798.798 0 0 0 .058.065c0 .002.002.003.003.004a.773.773 0 0 0 .144.11l.01.007 5.25 3a.75.75 0 0 0 1.123-.651v-9a.75.75 0 0 0-.373-.649Zm-1.127 8.357L18 13.065v-2.13l3.75-2.143v6.416ZM3.828 1.745a.75.75 0 1 0-1.11 1.01l1.927 2.12H2.25a1.502 1.502 0 0 0-1.5 1.5v9a3.754 3.754 0 0 0 3.75 3.75h12c.306 0 .605-.094.855-.27l3.09 3.4a.75.75 0 0 0 1.11-1.01L3.828 1.745Zm.672 15.88a2.253 2.253 0 0 1-2.25-2.25v-9h3.76l10.226 11.25H4.5Z"
          //    />
          //  </svg>

          <DisabledVideoIcon fill="#ffffff" testID="custom-svg-4" />
         }
        </div>

         <hr style={{marginRight: "21px",  border:"1px solid #94949457"}}/>   
     </div>
                 
                })
              } 

                </Space>
               }

                {/* Screen Sharing */}
                {isPresentationActive &&(
                  <ScreenSharingActionBar
                    statusLabels={{
                      active: screenSharingActionBarTexts.status.active,
                      error: screenSharingActionBarTexts.status.error,
                      loading: screenSharingActionBarTexts.status.loading,
                      other: screenSharingActionBarTexts.status.other,
                    }}
                    buttonLabels={{
                      tooltip: screenSharingActionBarTexts.button.tooltip,
                      label: screenSharingActionBarTexts.button.label,
                    }}
                    guestLabel={screenSharingActionBarTexts.guest}
                    // onClick={startScreenSharing}
                  />
                )}
                {/* Conference Recording */}
                {isRecordingActive && (
                  <RecordingActionBar
                    statusLabels={{
                      active: RecordingActionBarTexts.status.active,
                      error: RecordingActionBarTexts.status.error,
                      loading: RecordingActionBarTexts.status.loading,
                      other: RecordingActionBarTexts.status.other,
                    }}
                    buttonLabels={{
                      active: {
                        tooltip: RecordingActionBarTexts.buttonLabels.active.tooltip,
                        label: RecordingActionBarTexts.buttonLabels.active.label,
                      },
                      error: {
                        tooltip: RecordingActionBarTexts.buttonLabels.error.tooltip,
                        label: RecordingActionBarTexts.buttonLabels.error.label,
                      },
                    }}
                    guestLabel={RecordingActionBarTexts.guest}
                  />
                )}
               
              {isPresentationActive && isMaxScreen === true && (

              // 10% left - width 75%
              <Space  style={{position:"absolute",top:"12%",left:"7%",right:"7%",alignItems:"center",width:"174vh",height:"525px",display: 'flex' ,background:"rgb(1 18 38 / 80%)",margin:"0 auto",padding:"15px",borderRadius:"11px"}}>
                <div className='left-column'>
                  <div style={{position:"relative"}}>
                  {/* <h2>{status === 'active'? props.usern:''}</h2>  490px */}
                  <button  onClick={ScreenView} className='dolbyicon sidebtnshare'>
                       <img width="35px" src="assets/Minimize.svg" alt="" /></button>
                  <Space fw style={{ height: "100%",width:"", flexGrow: 1 }}>
                   
                    <ScreenSharingPresentationBox />
                  </Space>
                  <p style={{
                    position: "absolute",
                    background: "#011226",
                    color: "#fff",
                    bottom: "-15px",
                    left: "50%",
                    padding:"1px"
                  }}
                  
                  
                  >{isLocalUserPresentationOwner?participant.info.name:''}</p>
                  
                  </div>
                </div>


               <div className='right-column' >
                <div className='ParticipantsListShare right-subcolumn'>
                  <p><b>Participants</b></p>
                </div>
                <div style={{borderRadius:"11px"}}>
                <Space className='right-subcolumn' style={{borderRadius:"",marginLeft:"15px",display: "block !important", position:"", right:"0px", top:"207px", height: 'auto', flexGrow: 0,width:"38vh",background:"",overflowX:"clip",overflowY:"scroll", maxHeight:"387px" }}>
                   
                  <ParticipantsGrid  localText={localTextInGrid} additionalContainerStyle={{ }} />
                </Space> 
                </div>
                </div>
                </Space>
             

               )}

              {/* End of share component  */}

                {/* Media Control Buttons */}
                <div style={{ ...buttonContainerStyle, gap: '10px' }}>
                

          <div style={{backgroundColor:"#011226b3",textAlign:"center",borderRadius: "11px",margin: "0 auto",padding: "7px", position:"absolute", bottom:"15px", paddingRight: "12px" }} >

                  {/* Begining of ToolTips */}
             <ReactTooltip 
              anchorId="mic-unmic"
              place="top"
              content={!isAudio? 'Mute' : 'Unmute' }
             />
              <ReactTooltip
              anchorId="vid-unvid"
              place="top"
              content={isVideo2? 'Stop video' : 'Start video' }
             />

              <ReactTooltip
              anchorId="people"
              place="top"
              content={isList? 'Hide participants' : 'Show participants' }
             />

              <ReactTooltip
              anchorId="share-en"
              place="top"
              content={status === 'active' &&  isPresentationModeActive? 'Stop screen share' : 'Start screen share' }
             /> 

              {/* <ReactTooltip
               anchorId="captionTranslate"
                place="top"
                content='Caption/Subtitle'
              />  */}

             <ReactTooltip
              anchorId="end"
              place="top"
              content='End call'
             /> 

             {/* End of ToolTips */}
          
         
           <button id="mic-unmic"  className="dolbybtn" onClick={micUnmic} style={{width:""}}>
         
            {isAudio ? <img  src="assets/Mic-Disabled.svg" alt="" /> : <img src="assets/Mic-Enabled.svg" alt="" /> }
            
            </button>

            <button id="vid-unvid" className="dolbybtn" onClick={videoUnvideo} style={{width:""}}>
           

             {isVideo2 ? <img src="assets/Video-Enabled.svg" alt="" /> : <img src="assets/Video-Disabled.svg" alt="" /> } 
            
            </button>

            <button id="share-en" className="dolbybtn" onClick={startScreenSharing} style={{width:""}}>
            
            {status === 'active' && isPresentationModeActive? <img src="assets/Screenshare-Enabled.svg" alt="" /> : <img src="assets/Screenshare-Disabled.svg" alt="" /> }
           
           </button>

           <button id="people" className="dolbybtn" onClick={showParticipants} style={{width:""}}>  
            {isList ? <img src="assets/people.svg" alt="" /> : <img src="assets/hide_people.svg" alt="" /> }
           </button>

            <button id="end" className="dolbybtn" onClick={endCall} style={{width:""}}>
                <img src="assets/Endcall.svg" alt="" />
            </button>
            </div>
          {/* End of NavBar */}

                </div>
               
              </Conference>
            )}
          </Session>

        </div>
      )
    }

    export default Content;
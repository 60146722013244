import React, { useState, useRef, useEffect,useCallback } from 'react';
import { Card, ProgressBar } from 'react-bootstrap';
import { Modal, Button , Form} from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

const FileDropForm = (props) => {
  const [show, setShow] = useState(false);
  const [showText, setShowText] = useState(false);
  const [modalHeaderText, setMHT] = useState({title:'',subTitle:''})


  const [inputValue, setInputValue] = useState('');
  const [waitUpload, setWaitUpload] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');


  const handleClose = () =>  {
    setShow(false); 
    setShowText(false);
    console.log(showText)
   // sendMessage("JavaScriptMessageReciever", "Unlock");
  //  console.log('Clicked Prop ...')
   props.myCall('JavaScriptMessageReciever','Unlock')
};
  const handleShow = () => setShow(true);
  // const handleShowText = () => setShowText(true);
  const [errors, setErrors] = useState('');
  const [text, setText] = useState('');
  // const [url, setUrl] = useState('');
  const [dragging, setDragging] = useState(false);
  const [file, setFile] = useState(null);
  // const hiddenFileInput = useRef(null);
  var [uploadProgress, setUploadProgress] = useState(0);
  const fileInputRef = useRef(null);

  const [selectedOption, setSelectedOption] = useState('option1'); 

  const maxbps = 40960000;


    const search = window.location.search;
    const params = new URLSearchParams(search);
    const code_URL = params.get('code');

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    //handleUpload();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
  };




  const logResult = useCallback((event) => {
   // var message = event.data;
    // console.log(message)
   
   if(event.data.event === 'edit'){
      setMHT(values => ({
        ...values,
        title: event.data.edittype,
        subTitle:event.data.id
      }));
    // console.log('Open Modal Here') 
    // console.log("message event", event);
    // var message = event.data;
    // console.log(message)
    // console.log(event.data.event)
    // console.log(event.data.edittype)
    // console.log(event.data.id)
    handleShow();
   }
           
  }, []); 

  useEffect(() => {
    if(file === '' || file === null){setFile(null)}

     // fetch(`http://localhost/api.holofair.net-master/eventFiles/read_paging.php?code=7153208&keyword=${inputValue}`)
    fetch(`https://api.legacy.holofair.io/eventFiles/read_paging.php?code=${code_URL}&keyword=${inputValue}`)
        .then(response => response.json())
        .then(data => (data.records.length > 0)?setSuggestions(data.records): [{}])
        .catch(error => console.error('Error fetching data:', error));

    window.addEventListener('message', logResult);
    return () => {
    window.removeEventListener("message", logResult);
    
      };
      
  }, [file, inputValue, logResult, code_URL]);

  // const handleUnityLoaded = () => {
  //   // Unity WebGL build has loaded and rendered completely
  //   console.log('Unity WebGL build is ready.');
  // };

  // const handleChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };



  const onUnloadFile = () =>{
    fileInputRef.current.value = null
     setFile(null)
   // setUploadProgress(0)
    uploadProgress = 0;
    
    setErrors('');
    setUploadProgress(0)
    console.log(uploadProgress)
  }
  var allowedTypes = []

  const onLoadfile = (e) => {
    var file2 = e.target.files[0];
    if(modalHeaderText.title === 'image'){
      allowedTypes = ['image/jpeg', 'image/png'];
      if (file2 !== '' && allowedTypes.includes(file2.type)) {
        setFile(file2);
        setErrors('');
        const timer = setInterval(() => {
        
          if ( uploadProgress < 100) {
            setUploadProgress(uploadProgress + 100);
          } else {
            clearInterval(timer);
            setUploadProgress(0)
          }
        }, 2000); 
      
      } else {
        setFile(null);
        setErrors('Invalid file type');
      }
    }else{
      allowedTypes = ['video/mp4',];
      if(file2 !== '' && file2.type === "video/mp4"){
        const file2 = e.target.files[0];
        setFile(file2)
  
        const video = document.createElement('video');
        video.src = URL.createObjectURL(file2);
        console.log('File Details', file2)
    
        video.addEventListener('loadedmetadata', () => {
            console.log(`File:`, file2);
            console.log(`size: ${file2.size} bytes`);
            console.log(`duration: ${video.duration} seconds`);
            const bitsPerByte = 8;
            const bps = (file2.size * bitsPerByte) / video.duration ;
           
            console.log(bps);
            if (bps > maxbps) {
                console.log(`Data rate exceeds ${maxbps} bps. Aborting upload.`);
                setErrors('Data rate exceeds the limit. Upload aborted')
                setFile(null);
            }else{
                setFile(file2);
                setErrors('')
            }
        })
    }else{
      setFile(null);
        setErrors('Invalid file type');
    }

}
}

  const handleBlur = (event) => {
    console.log("Left Field", event.target.value)
    //setFile(event.target.value)
    setInputValue(event.target.value)
    //setText('This field cannot be empty')
    if(event.target.value.length < 1)
    setText('This field cannot be empty')
    else{
      setText('')
    }
   
  };

  const handleSelect = (value) => {
    setSelectedValue(value);
    setInputValue(value);
    setSuggestions([]);
  };

  const SendReq = async () => {
    // console.log('Send Data')
    // console.log(file);
    setWaitUpload(true)
   
    const formData = new FormData();
      formData.append('file', file);
      formData.append('code', code_URL);

      const DBdata = 
      {
          "userid":-1,
           "url":(file?"https://cdn.holofair.io/EventFiles/"+code_URL+"/"+file.name:inputValue),
           "code":code_URL
      }
    try{
      console.log(formData)
      if(file){
      // axios.post('http://localhost/api.holofair.net-master/upload-to-s3.php', formData, {headers: {
      axios.post('https://api.legacy.holofair.io/upload-to-s3.php', formData, {headers: {
        'Content-Type': 'multipart/form-data',
      },})
      .then(response => {
       
        props.myCall("JavaScriptMessageReciever", "Unlock")
        //sendMessage("JavaScriptMessageReciever", "Process", String(file?file.name:inputValue));
        setTimeout(() => {
        props.myCall("JavaScriptMessageReciever", "Process", String(response.data.succeeded[0].url));
          handleClose()
          setWaitUpload(false)
        }, 1000);

      })
      .catch(error => {
        console.log(error.message);
        // setData({});
      });
    }else{
        props.myCall("JavaScriptMessageReciever", "Unlock")
        setTimeout(() => {
          props.myCall("JavaScriptMessageReciever", "Process", String(inputValue));
          setWaitUpload(false)
          handleClose()

          axios.post('https://api.legacy.holofair.io/eventFiles/create.php', DBdata, {
          })
          .then(response => {
           // console.log('Response:', response.data);
          })
          .catch(error => {
            console.error('Error:', error);
          });

        }, 1000);

       
      }
      
        setInputValue('')
        setSelectedValue(null)
        setSelectedOption('option1')
        onUnloadFile()
  }
  catch (error) {
    console.error('POST error:', error.response);
     }
  }

  

  const handleOptionChange = (event) => {
    console.log(selectedOption)
    setSelectedOption(event.target.value);
  };

  return (
   <div>


    
    <Modal show={show} onHide={handleClose}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    style={{height:'630px'}}
    >   
        <div style={{background:"#1f2b37"}}>
        <button className='closeModalBtn' onClick={handleClose}>X</button>
          <Modal.Title style={{color:"#fff",paddingLeft:"15px"}}>Edit {modalHeaderText.title}</Modal.Title>
          <h5 style={{color:"#fff",paddingLeft:"15px",fontSize:"9pt"}}>{modalHeaderText.subTitle}</h5>
          </div>
    <Modal.Body>

<div className="row">
  <div className="column">
 
    <Card style={{background:"rgb(175 175 175)",
          cursor: "pointer",
          borderRadius: "25px",
          width: "250px",
          pointer:"cursor"
        }}
      className={`p-5 text-center ${dragging ? 'bg-primary text-white' : ''}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onChange={onLoadfile}
      onClick={() => fileInputRef.current.click()}
    >
      <input 
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        // name='fileupload'
      />
      {file ? (
        <div >
          {/* <p>
            File Selected: <strong>{file.name}</strong>
          </p> */}
          <p>File Uploaded</p>
          {!errors && file && 
          <div>
          <ProgressBar animated now={uploadProgress} label={`${uploadProgress}%`} /> 
          </div>
           }
          {/* {uploadProgress !== 100 && <button disabled>Uploading...</button> } */}
        </div>
      ) : (
        
       
        <div>
          <img width='50px' src='upload.svg' alt=''/>
        <p>Click or Drag & Drop a file here to upload.</p>
        </div>
      )}
    </Card>
    <div style={{marginLeft:'10px',marginTop:'5px'}}>
    <div style={{width:'250px'}}>
    {/* {uploadProgress > 0 &&  */}
    {/* <div>
    <ProgressBar animated now={uploadProgress} label={`${uploadProgress}%`} /><button onClick={onUnloadFile}>X</button> 
    </div> */}
    {/* } */}
    </div>
    
      <p style={{color: '#CE2216'}}>{errors}</p>
      
      {file &&
      <p><strong>{file.name}</strong>  
      <button style={{backgroundColor:'unset',border:"none",color:"#f82626d4"}} onClick={onUnloadFile}>X</button>
      </p>
       }

    <Form.Text className="text">
      <b style={{color: '#1f2b37',fontSize: '15pt'}}>Allowed Files<br></br></b> 
        {modalHeaderText.title === 'image'?'JPEG, PNG':'MP4'}
      </Form.Text>
     
    </div>
   

    </div>



    <div className="column">
    <label>Or Upload Via URL</label>
    <select disabled={file} value={selectedOption} className="input-field" onChange={handleOptionChange}>
        <option value="option1">Choose an option</option>
        <option value="option2">Insert a URL</option>
        <option value="option3">Search for an existing URL</option>
      </select>

{selectedOption === 'option2' &&
    <Form.Group>
      <Form.Label>URL <span style={{color:"red"}}>*</span> </Form.Label>
      <Form.Control style={{border:text?'1px solid red':'',color:text?"#000 !important":"#000 !important"}} as="textarea" rows={5}  onBlur={handleBlur} />
      {text && <Form.Text className="text-danger">{text}</Form.Text>}
    </Form.Group>
}
    <br></br>

 


{selectedOption === 'option3' &&
 <div className="">
    <label>Search for an Existing URL</label>
      <input
        type="text"
        // onFocus={()=> set('')}
        value={inputValue}
        onChange={(e) => {setInputValue(e.target.value); setSelectedValue('')}}
        placeholder="Type to search..."
        className="input-field"
      />
      {/* {!selectedValue && inputValue && */}
      {!selectedValue &&
      <ul className="suggestions-list">
        {suggestions.map((item, index) => (
          <li key={index} onClick={() => handleSelect(item.url)}>
           {item.url}
          </li>
        ))}
      </ul>
}
      {selectedValue && <p className="selected-value"
      style={{width: "300px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"}}
      >
        {/* <b>URL Selected:</b> {selectedValue} */}


        {selectedOption === 'option3' &&
      <div>
      {modalHeaderText.title === 'image'?
      <img src={inputValue} alt='' style={{width:"300px",borderRadius:"10px"}}/>:
      <video controls width="300" height="180">
        <source src={inputValue} type="video/mp4" />
      </video>
}
        

      </div>
}
        
        </p>}
  </div>
}
      </div>
    </div>

          
  </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {!waitUpload?
          <Button variant="primary" onClick={SendReq} type="button" form="myForm">
            Upload
          </Button>:

          <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
          }
        </Modal.Footer>
    </Modal>


     </div>

  );
};

export default FileDropForm;



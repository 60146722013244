
import '../styles/App.css';
import React , {useState, useEffect, useCallback,useRef} from 'react' // react import
import axios from 'axios';
import { StreamChat } from 'stream-chat';
import "stream-chat-react/dist/css/index.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import {
    Chat,
    ChannelList,
    Channel,
    Window,
    MessageInput,
    MessageList,
    ChannelHeader,
  } from "stream-chat-react"

function ChatApp(props) {
 
const [selectedChannel, setSelectedChannel] = useState(null);
 const [token, setToken] = useState(false);
//  const [showchat, setshowchat] = useState(true);
 const[otheruser,setotheruser] = useState(false);
 const [minimized, setMinimized] = useState(true);
 const [channel, setChannel] = useState(null);
 const [isLoggedIn, setLogin] = useState(false);
 const [emotes, openEmotesModal] = useState(false);
 const [avatar, openAvatarModal] = useState(false);
 const [badge, setmyCount] = useState(null)
 const apiKey = 'wtqpr8a4dpqx';
 const authToken = localStorage.getItem('chatToken')
 const user = localStorage.getItem('chatUser')
  const client = StreamChat.getInstance(apiKey);
  var uuid = ''
  var timer;

   const mylocalname = useRef('')


  const handleMinimize = () => {
    console.log(minimized)
    //setMinimized(!minimized);
    setMinimized(true);
    setotheruser(false)
  };
  
  function callme(uid,dn){

   // console.log('#@#@#@#@'+uid)
    
    const d = {id:uid,name:dn,image:""};

    try {
        axios
        //.post(`https://getstream.holofair.io/login`,d )
        .post(`https://getstream.holofair.io/signup`,d )
      //  .post(`http://localhost:3005/login`,d )
       
        .then(res => {
          //console.log(res)
          localStorage.setItem('chatToken',res.data.token)
          localStorage.setItem('chatUser',res.data.user.id)
          setToken(true);
          return res.data.token;
        })
      } catch (error) {
        console.error('Error fetching token:', error);
      }
}





  const openchatbox = useCallback(() => {
    
  if(minimized)
   setMinimized(false);


  },[minimized]);




  const handleusername = useCallback((mySession,id,username,myDisplayName)=>{
    localStorage.setItem('chatUser','')
    localStorage.setItem('chatToken','')
    localStorage.setItem('uuid','')
    localStorage.setItem('usergs','')
    localStorage.setItem('dn','')
   // console.log("OnLOAD",mySession,id,username,myDisplayName)
    mylocalname.current = myDisplayName;
    localStorage.setItem("uuid",id);
    localStorage.setItem("usergs",id);
    localStorage.setItem("dn",myDisplayName);
  },[]);


 const handleCallBackFromUnity = useCallback( async (id,sfs,s,m) => {
  console.log("show me the id "+id + " "+sfs +" " + s + " " + m + "")
  console.log(isLoggedIn)
  var y = crypto.randomUUID()
   
    if(id !== '' && isLoggedIn === true){
      axios.get("https://zoomsdk.holofair.io/streamdata",{params:{uid:localStorage.getItem('usergs'),uid_other:id}}).then((res)=>{
        try{
         // console.log("TRYYY")
         console.log(res.data.length)
        if(res.data.length > 0){
   
       // console.log(res.data[0].uid +'______________'+res.data[0].uid_other)

        const channel= client.channel('messaging',res.data[0].cid );
         channel.watch();
          setChannel(channel);
          
            //console.log('Users --------->')
            // console.log(res.data[0].uid + '' + res.data[0].uid_other)
            // console.log(res.data[0].cid)
            setSelectedChannel(res.data[0].cid);
           // console.log(res.data[0].cid)
            console.log(channel)
            setotheruser(false)
            setMinimized(true);
            
            
          }else{

           // console.log('#############@@@@@@########')
           // console.log(val);
            try {

             var ch =  client.channel("messaging",y , {
                name: s +  " - " + mylocalname.current ,
                image: '',
                members: [user,id],
              }).create()

          

            if(ch){
              // axios.post(`https://zoomsdk.holofair.io/streamstore`,{name:m,uid:localStorage.getItem('usergs'),uid_other:id,cid:y} ).then(res => {
              //   console.log("Chat has been created" + uuid + ' | ' + id + ''+ localStorage.getItem('usergs'))
                

              //   setSelectedChannel(ch)
                

              //    if(selectedChannel !== null){
              //       setTimeout(() => {
              //     axios.get("https://zoomsdk.holofair.io/streamdata",{params:{uid:localStorage.getItem('usergs'),uid_other:id}}).then((res)=>{
              //       console.log('Channnnnnnnel',res.data.length)
              //     if(res.data.length > 0){
                  
              //     const channel= client.channel('messaging',res.data[0].cid );
              //      channel.watch();
              //      setSelectedChannel(channel)
              //       setChannel(channel);
              //       setotheruser(true)
              //       setMinimized(false);
              //     }
              //       })    
                  
              //   },2500)
              // }
              // })
            }
            } catch (error) {
              console.error('Error fetching token:', error);
            }

             localStorage.setItem('uuid',uuid)
            //  setTimeout(() => {
              
              
            //  }, 1500);
          }
        }catch (err){
         console.log("Something went wrong: "+err)
         alert('Something went Wrong. Please try to reopen the chat or refresh the page')

        }
        // ns = res.data[0].name
      }) 

    } else{
      alert('User Has not been sucessfully logged in. Please try to reopen the chat or refresh the page')
          }

  //  console.log(minimized)
  //  if(minimized === true){
  //   setotheruser(true);
  //  }
  if(otheruser === false){
     console.log('Something went Wrong. Please try to reopen the chat or refresh the page')
  }
   
  },[client, isLoggedIn, otheruser, user, uuid])

  const chatHistory = () => {
    setotheruser(false)
    setMinimized(false);
  }

  const emotesModal = () => {
   openEmotesModal(!emotes)
  }

  const avatarModal = () => {
  openAvatarModal(!avatar)
    
  }

  const onLoadAction = useCallback(() =>{
    localStorage.removeItem('chatUser')
    localStorage.removeItem('chatToken')
    localStorage.removeItem('uuid')
    localStorage.removeItem('usergs')
    localStorage.removeItem('dn')
    client.disconnectUser()
  },[client])


  useEffect(() => {
 //  console.log(isLoggedIn)
    var intervalId = '';
    intervalId =  setInterval(() => {
      if(isLoggedIn === true && localStorage.getItem("chatToken") !== ''){
      clearInterval(intervalId)
      client.connectUser({
              id: user,
              name: user,
              image: "",
          }, localStorage.getItem('chatToken'))
          client.on((event) => {
            if (event.total_unread_count !== undefined) {
                console.log(event.total_unread_count);
                setmyCount(event.total_unread_count)
            }
       });
       } else{
      if(localStorage.getItem("usergs") !== null){
        callme(localStorage.getItem("usergs"),localStorage.getItem("dn"));
        setLogin(true)
        }
      }
          
        }, 5000);

    if(isLoggedIn){
    props.myEvent("GetStreamIOChat",handleCallBackFromUnity);
    }

    props.myEvent("ExternalLogin",handleusername);
    props.myEvent("OpenChatBox",openchatbox);
    return () => {
    props.myCleanUp("OpenChatBox",openchatbox);
    props.myCleanUp("GetStreamIOChat",handleCallBackFromUnity);
    props.myCleanUp("ExternalLogin",handleusername);
    clearTimeout(timer);
    clearInterval(intervalId);
  

    };
  }, [handleusername, handleCallBackFromUnity, openchatbox, authToken, client, user, isLoggedIn, onLoadAction, props, timer]);

  const handleClick23 = () => {
   
   // console.log('clicked on');
   props.myChat("App","ToggleWebGLInputAutoCapturing",1)
   // canvasRef.current?.focus();
  };
  
  // const handleClick21 = () => {
  //   console.log('clicked off');
  //   props.myChat("App","ToggleWebGLInputAutoCapturing",0)
  // //  canvasRef.current?.focus();
  // };

  // const filter2 = { type: 'messaging', members: { $in: [user,chatWithID] } };




 


  //const sort = [{ last_message_at: -1 }];
  // const sort = { last_message_at: -1 };


  const chatUI = 
  `
  .rta__textarea.str-chat__textarea__textarea.str-chat__message-textarea{
    color: #fff !important;
    width: 85% !important;
    margin-left: 95px !important;
  }

  // #webCanvas{
  //   width: 50vw !important;
  // }

  .str-chat__send-button{
      width: 4.5% !important;
  }  



  @media screen and (min-width: 1370px) {
          
    #webCanvas{
      width: 65.5vw !important;
    }
  }


  
  @media screen and (max-width: 1370px) {
    
    #webCanvas{
      width: 85vw !important;
    }
  }
  `;

  const chatUI2 = `

  .rta__textarea.str-chat__textarea__textarea.str-chat__message-textarea
  {
    color: #fff !important;
    width: 80% !important;
    margin-left: 80px !important;
  }

  .str-chat__send-button{
    width: 6.5% !important;
    }  


    @media screen and (min-width: 1370px) {
          
      #webCanvas{
        width: 55.5vw !important;
      }
    }
    @media screen and (max-width: 1370px) {
      
      #webCanvas{
        width: 77vw !important;
      }
    }




  `;

  return (

   
    <div className="App3"  >
   
      <style>
        {otheruser === true?chatUI:chatUI2}
      </style>


     

         
             <div style={{ 
                backgroundColor:"#011226b3",
                textAlign:"center",
                // width: "45vh",
                borderRadius: "11px",
                padding: "7px",
                position:"absolute",
                bottom:"0px",
                right:"0px",
                margin:"11px",
                display:"none"
                }} 
              >
             
             
              <button  className="dolbybtn paddingbtn"  style={{width:"25"}}>
                 <img  src="assets/PublicChat.svg" alt="" />
              </button>

              <button  className="dolbybtn paddingbtn" onClick={chatHistory}   style={{width:"25"}}>
                 {badge &&<span class="badge">{badge}</span> }
                 {minimized?<img  src="assets/MinChat.svg" alt="" />: <img  src="assets/MaxChat.svg" alt="" />}
              </button>

              <button  className="dolbybtn paddingbtn" onClick={emotesModal}  style={{width:"25"}}>
                 <img  src="assets/Emote.svg" alt="" />
              </button>

              <button  className="dolbybtn paddingbtn"   onClick={avatarModal}  style={{width:"25"}}>
                 <img  src="assets/avatar.svg" alt="" />
              </button>
        

              </div>
          
      {avatar &&
      <div style={{position:"absolute",paddingLeft:"15px",paddingReft:"15px",borderRadius:"15px", color:"#fff",right:"10px",bottom:"135px", height: '270px',width:"30%", margin:'auto',background:"rgb(1 18 38 / 85%)",overflow:"hidden" }}>
        <button className='closebtnmodal' onClick={avatarModal}>x</button>
        <div style={{padding:"15px"}}>
        <h4>Hello , I am here</h4><br />
        <button className='emotes_btn'>AVATAR</button>
        <button className='emotes_btn'>DISCOUNNECT</button>
        <h6>Company : N/A</h6>
        <h6>Email : ayastech7@gmail.com</h6>
        <h6>Phone : N/A</h6>
        <h6>Website : N/A</h6>
        
        </div>
       
      </div>

      }

      {/* Emotes */}

      {emotes &&
      <div style={{position:"absolute",paddingLeft:"15px",paddingReft:"15px",borderRadius:"15px", color:"#fff",right:"10px",bottom:"135px", height: '450px',width:"35%", margin:'auto',background:"rgb(1 18 38 / 85%)",overflow:"hidden" }}>
         <button className='closebtnmodal' onClick={emotesModal}>x</button>
        <h4 style={{padding:"15px"}}>Emotes</h4>
        <div style={{padding:"15px",display: "grid",gridTemplateColumns: "repeat(3, 1fr)",gap: "3px"}}>
        
        <div style={{background:"white",width:"150px",height:"150px"}}></div>
        <div style={{background:"white",width:"150px",height:"150px"}}></div>
        <div style={{background:"white",width:"150px",height:"150px"}}></div>
        <div style={{background:"white",width:"150px",height:"150px"}}></div>
        <div style={{background:"white",width:"150px",height:"150px"}}></div>
        <div style={{background:"white",width:"150px",height:"150px"}}></div>
        
        </div>
       
      </div>
      }

   <div style={{width:"750px"}}>
  
      

  {isLoggedIn && minimized === false  &&
  <div onClick={handleClick23}  id='webCanvas' style={{position:"fixed",bottom:"97px",right:"0",height:'70vh',margin:'11px',display:!minimized?'block':'none'}}>
    {/* <div onClick={showchannel}> <span id="togglebtnarrow"> {showchat? <span>&rarr;</span> : <span>&larr;</span>}</span>  </div> */}
    
    


{minimized === false &&
    //   <button style={{color:"#17222e",fontWeight:"900",fontSize:"27px",float:"right", lineHeight:"1px", margin:"0px",borderRadius:"",width:"3%",height:"27px",border:"",background:"",position:"absolute",zIndex:"1000000000000",right:"7px",top:"7px"}} onClick={handleMinimize} >
    //   {minimized ? '' : <span>&minus;</span>}
    // </button>
   <div>
       <button style={{color:"white",float:"right", lineHeight:"1px", margin:"0px",borderRadius:"50px",width:"35px",height:"35px",border:"1px solid #dc3545",background:"#EA4335",position:"absolute",zIndex:"1000000000000",right:"17px",top:"3%"}} onClick={handleMinimize} >
            X
    </button>

    <div style={{color:"white",float:"left", lineHeight:"", margin:"0px",width:"200px",height:"72px",position:"absolute",zIndex:"1000000000000",left:"17px",top:"2%"}} onClick={handleMinimize} >
            <span style={{
              fontSize: "17pt",
              fontWeight: "500"
            }}>Messages </span>
            {badge &&
            <div style={{
               width:"35px",
               height:"35px",
               float:"right",
              fontSize: "16pt",
              marginRight:"50px",
              fontWeight: "bold",
              borderRadius:"50px",
              textAlign:"center",
              background:"#fff",
              color:"black",
            }}>{badge} </div>}
    </div>

   </div>
      }
  {minimized === false && 
  <Chat client={client}  >
    {otheruser === false &&
       <ChannelList
      filters={{ members: { $in: [user] } }}
       />
    }
{otheruser === true &&
       <ChannelList
      filters={{ members: { $in: [user] } }}
       />
    }
       <Channel 
       >
         <Window>
           <ChannelHeader />
           <MessageList />
           <MessageInput />
         </Window>
       </Channel>
      </Chat>
}
     
    </div>
}
</div>
      <div>
      </div>
    </div>
  )
}

export default ChatApp;


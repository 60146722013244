 import{
    CommsProvider,
    ThemeProvider,
    useSession
  } from '@dolbyio/comms-uikit-react';
 import VoxeetSDK from '@voxeet/voxeet-web-sdk';
 import React, { useEffect, useState,useCallback } from 'react';
 import {UNITY_URLS} from './utils/config';
 import "react-tooltip/dist/react-tooltip.css";
 import { Unity, useUnityContext } from "react-unity-webgl";
 import FileDropForm from "./components/modal"
//  import axios from 'axios';
//  import io from 'socket.io-client';
 import ChatApp from './components/chat';
 import ZoomMeeting from './components/zoom';
 import Content from './components/video/VideoUI'

    async function getAccessToken() {
        //const response = await fetch("http://localhost:5000/dolbyauth");
       const response = await fetch("https://dolbyauth.holofair.io/dolby")
        const json = await response.json();
        //console.log("AAL")
        console.log(json.access_token)
       // token = await json.access_token;
        return json.access_token;
      }

   
    const AppBase = ({ children }) => {
    
        useEffect(() => {
          localStorage.setItem('leaveSession','no')
          let isSubscribed = true;
         if(isSubscribed)
             return () => {
             isSubscribed = false
          };
          }, []); 

        return (
          <ThemeProvider>
            <CommsProvider token={getAccessToken} refreshToken={getAccessToken}>
              {children}
            </CommsProvider>
          </ThemeProvider>
        );
      };

 

  const App = () => {
    const [conferenceUID, setConferenceUID] = useState()
    const [u, setusern] = useState()
    const [m, setmeetingn] = useState()
    const [r, setrole] = useState('')
    const [isOpenbtn, setIsopen] = useState(false)
    const[isCall,setCall] = useState()
    const { closeSession } = useSession();
    const [progress, setProgress] = useState(0);
    const { unityProvider, sendMessage, addEventListener, removeEventListener, loadingProgression,isLoaded } = useUnityContext(UNITY_URLS);

    function funcOpen(e){
      setIsopen(e)
    }


    const checking = () => {
      if(localStorage.getItem("lbc") !== ''){
        VoxeetSDK.conference.leave();
        VoxeetSDK.session.close();
        console.log("closing")
        localStorage.setItem("lbc","")
      }

    }

    const connect = useCallback( async (username,roomname,role)=> {
     localStorage.setItem('ppid','')
     if(localStorage.getItem('leaveSession') === 'yes'){
      console.log('yes')
      return;
      }

    else{
      checking();
      setCall(false)
      
      try {
        //closeSession()
       // VoxeetSDK.conference.leave()
        console.log(`Connecting using data`, )
        setusern(username)
        setmeetingn(roomname)
        setrole(role)

        

        if (VoxeetSDK.conference.current && localStorage.getItem("lbc") !== '' ) {
          alert('Already in a session. Please leave the current session before joining another.');
          return;
        }
        await VoxeetSDK.session.open({
          name: username,
          userRole: '1'
        });
    
        console.log(`Connected`)
        try {
          console.log(`Creating conference`)
          const conference = await VoxeetSDK.conference.create({
            alias: roomname
          });
          console.log(`Joining conference`)
          await VoxeetSDK.conference.join(conference, {
            
            // simulcast: true,
            constraints: {
              audio: false,
              video: false
            }
          });
         
          console.log(`Joined conference`)
          console.log(conference.id)
          setConferenceUID(conference.id)
         // setInsert(conference.id,role);
          // setIsopen(true)
          // setCall(true)


          if(progress >= 100){
            setProgress(0)
            setIsopen(true)
            setCall(true)
          
          }

          localStorage.setItem('callstatus','Already in a call')

        } catch (error) {
          console.log(error);
        }
      } catch (e) {
        console.error('Connection failed, handle error', e);
      }
    }
    },[progress])

   

   


    const joinDolbyfunc = useCallback((username, roomname,lang, role)=>{
      
      console.log("My data",username, roomname, role)
      //  var myrole = role === 0 ? 'speaker' :role
      
      var myrole = role
          if(localStorage.getItem('callstatus') !== '' && VoxeetSDK.session.isOpened === true){
            alert("You cannot make another call while you are already in a call")
            
          }else{
            connect(username,roomname,myrole)
            }

        },[connect]);



    const loadingPercentage = Math.round(loadingProgression * 100);


    const onLoadAction = () => {
        // isOpenbtn === true ? setIsopen(false) : setIsopen(true);
        localStorage.setItem('callstatus','')
    }



  const receiveMessageUnity = useCallback((event) =>{
    
    //console.log("message event", event);
    var message = event.data;
    // const y = searchParams.get('uuid').substring(5)
    // console.log(y)
    //console.log("type", event.origin);
    var whitelisted = ["https://stgmyhctwebapp.hct.ac.ae", "https://hcttest.holofair.io", "https://myhctapps.hct.ac.ae", "https://eduverse.hct.ac.ae"];
    if (whitelisted.includes(event.origin)) {
      if (message.startsWith('uuid:')) {
        var uuid = message.substring(5);
        //console.log("receiveMessage", uuid);
        if(unityProvider){
          sendMessage("App", "SetUUID", uuid);
        } else {
         // console.error("No unity instance was found.");
        }
      }
    }
  },[sendMessage, unityProvider])


  const leaveDolbyfunc = useCallback(()=>{
    if(isCall === false){
    console.log("Leaving Before the call get started")
      localStorage.setItem("lbc",'y')
      setProgress(0)
      setIsopen(false)
      funcOpen(false)
      setCall(true)
         
        
    }else(
      console.log('Content endcall called')
    )
  },[isCall])


  useEffect(() => {


    const handlePaste = (event) => {
      event.preventDefault();
      navigator.clipboard.readText().then(text => {
        console.log('Pasted text:', text);
      
        sendMessage('Clipboard', 'Paste', text);
      }).catch(err => {
        console.error('Failed to read clipboard contents: ', err);
      });
    };

    const handleCopy = (event) => {
      event.preventDefault();
 
      sendMessage('Clipboard', 'Copy');
    };

    document.addEventListener('paste', handlePaste);
    document.addEventListener('copy', handleCopy);

    return () => {
      document.removeEventListener('paste', handlePaste);
      document.removeEventListener('copy', handleCopy);
   
    };

  }, [sendMessage])


    useEffect(()=> {
      localStorage.setItem('callstatus','')

      if(localStorage.getItem("progress") !== '') {
        localStorage.setItem("progress","") 
        setProgress(0)
      }


      const updateProgress = () => {
        setProgress(oldProgress => {
            if (oldProgress === 100) {
                clearInterval(timer);
                setCall(true)
                setIsopen(true)
                localStorage.setItem("progress",progress)
                return 100;
            }
            
            return Math.min(oldProgress + 10, 100);
        });
      //  console.log(progress)
      
    };

    checking();
    
    

    const timer = isCall === false ?setInterval(updateProgress, 200):0;

    

    addEventListener("joinDolby",joinDolbyfunc);
    addEventListener("leaveDolby",leaveDolbyfunc);
    window.addEventListener('message', receiveMessageUnity, false);
    window.addEventListener('beforeunload', onLoadAction);
    
      return () => {
        removeEventListener("joinDolby",joinDolbyfunc)
        removeEventListener("leaveDolby",leaveDolbyfunc);
        window.removeEventListener('message', receiveMessageUnity, false);
        window.removeEventListener('beforeunload', onLoadAction);
        clearTimeout(timer);
        // clearInterval(timer)
      }

    },[addEventListener, isCall, joinDolbyfunc, leaveDolbyfunc, m, progress, receiveMessageUnity, removeEventListener, u])
    
    const unityCtrlOff = () => {
      console.log('clicked off');
      sendMessage("App","ToggleWebGLInputAutoCapturing",0)
    };
   

   
    return (
      <>
    
        <React.StrictMode>
        <AppBase>
        <FileDropForm  myCall={sendMessage} />
        {/* <ChatApp  myChat={sendMessage} myEvent={addEventListener} myCleanUp={removeEventListener} /> */}
          {isCall === false  && progress < 100 &&
            <div className='spinnerCall'>
                <div>
                  <progress style={{width:"350px",height:"5px"}} value={progress} max="100"></progress>
                  <p style={{textAlign:"center", background: "#011226",width: "45px",margin: "auto",color: "#fff", borderRadius: "50px"}}
                  >{progress}%</p>
                </div>
            </div>
             } 
           
        {isOpenbtn && conferenceUID &&
          <Content  update={funcOpen} usern={u} mname={m} id={conferenceUID} role={r}  videoTag={sendMessage}/> 
        } 
       {isLoaded === false && (
       <div className="progress-overlay">
         <div className="progress-bar">
         <h2>{loadingPercentage}%</h2>
         <progress style={{margin:"0 auto",width:"80%",height:"50%",border:"1px solid #fff"}} id="file2" value={loadingPercentage} max="100">({loadingPercentage}%)</progress>
         </div>
       </div> 
       )}
       <div  onClick={unityCtrlOff}>
       <Unity  style={{ width: "100%",height:"100vh" , visibility: isLoaded ? "visible" : "hidden"}} unityProvider={unityProvider} />
       </div>

       {/* <ZoomMeeting controls={sendMessage} zoomEstablish={addEventListener} zoomClose={removeEventListener} /> */}

       {/* <div onClick={handleClick23} id="meetingSDKElement">
       </div> */}
        </AppBase>
        </React.StrictMode>
      </>
    )
  }

  export default App;
import React , {useEffect, useCallback,} from 'react' // react import
import axios from 'axios';
import ZoomMtgEmbedded from "@zoomus/websdk/embedded"
//import { Alert } from 'react-bootstrap';
const  { ZoomMtg } = require('@zoomus/websdk')

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.12.2/lib', '/av');
ZoomMtg.i18n.load('en-US');

var sdkKey = 'ilIQzR7RXaBcKqIMRLqLg';
var sdkSecert = "bKi49bp8ZL766EabOdhkrhzr7ecUsv0q";



function ZoomMeeting(props) {


    const handleCreateZoom = useCallback((id,cname,meetingname)=> {
        console.log("Creating:")
       
      axios.get("https://zoomsdk.holofair.io/zoomdata",{params:{mn:meetingname}}).then((res)=>{
      
          console.log(res.data.length);
          if(res.data.length > 0){
            console.log('#1');
            // console.log(meetingname);
            // console.log(cname);
          if(res.data[0].name === meetingname && res.data[0].status === 'created'){
           // console.log("Meeting Exists"+ res.data[0].mn);
            alert("Meeting already exists "+ res.data[0].mn);
            // startMeetingPopup(res.data[0].mn,res.data[0].pwd,cname,0)
          }
        }
        
        else{
          console.log("Meeting is not created with this name"+ meetingname);
    
          axios.get("https://zoomsdk.holofair.io/zoomapi/createMeeting",{params:{nz:meetingname}}).then((res)=>{ 
                  const {data} = res;
                //   console.log(data.id);
                //   console.log(data.encrypted_password);
           
                 // console.log('trace2');
                  console.log('Creating ...')
                  startMeetingPopup(data.id,data.encrypted_password,cname,1)
        });
  
        }
         });
      },[]);
  
      function startMeetingPopup(a,b,c,d){
        document.getElementById('zmmtg-root').style.display = 'block'
        ZoomMtg.generateSDKSignature({
          sdkKey:sdkKey,
          sdkSecret:sdkSecert,
          meetingNumber:a,
          role:d,
          success:function(res){
            ZoomMtg.init({
              debug:true,
              disablePreview: true,
              leaveUrl: "https://",
              isSupportAV: true
           
            });
            
            const client = ZoomMtgEmbedded.createClient()
            let meetingSDKElement = document.getElementById('meetingSDKElement')
           try{
            client.init({ debug:true, zoomAppRoot: meetingSDKElement, language: 'en-US' ,
            customize: {
                video: {
                  isResizable: true,
                  popper: {
                    disableDraggable: false
                  }
                },
                
                },
                meetingInfo: ['topic', 'host', 'mn', 'pwd', 'telPwd', 'invite', 'participant', 'dc', 'enctype'],
                toolbar: {
                  buttons: [
                    {
                      text: 'Custom Button',
                      className: 'CustomButton',
                      onClick: () => {
                        console.log('custom button');
                      }
                    }
                  ]
                }
              })
      
            client.join({
                  sdkKey: sdkKey,
                  signature: res.result,
                  meetingNumber: a,
                  password: b,
                  userName: c
                  })
  
            if (res.errorMessage === 'Already has other meetings in progress') {
             console.log('Already has other meetings in progress');
           } else {
             console.error('Error joining meeting:', res);
           }
            
          }catch (error){
      
          }
          },
          error:(error) =>{
            alert(error);
            console.log('Failing');
             console.log(error);
          }
        })
      
      
      }
  
  
      const handleleavemeeting = useCallback(()=>{
      
            leaveZoomMeeting();
        },[]);
  
  
          const leaveZoomMeeting = () => {
          ZoomMtgEmbedded.destroyClient()
  
            };
  
    const handleJoinZoom = useCallback((id,cname,meetingname)=>{
  
      axios.get("https://zoomsdk.holofair.io/zoomdata",{params:{mn:meetingname}}).then((res)=>{
           console.log('#############');
           console.log(res.data.length);
           if(res.data.length > 0){
            //  console.log('trace1');
            //  console.log(meetingname);
            //  console.log(cname);
           if(res.data[0].name === meetingname && res.data[0].status === 'created'){
             console.log("Meeting Exists"+ res.data[0].mn);
            // Alert("Meeting already exists "+ res.data[0].mn);
              startMeetingPopup(res.data[0].mn,res.data[0].pwd,cname,0)
           }
         }
        });

    },[]);

    const unityCtrlOn = () => {
        console.log('clicked on');
       props.controls("App","ToggleWebGLInputAutoCapturing",1)
      };

    useEffect(()=>{
        
        props.zoomEstablish("CreateZoomSession",handleCreateZoom);
        props.zoomEstablish("LeaveZoomSession",handleleavemeeting);
        props.zoomEstablish("JoinZoomSession",handleJoinZoom);

        return () => {
            props.zoomClose("LeaveZoomSession",handleleavemeeting);
            props.zoomClose("CreateZoomSession",handleCreateZoom);
            props.zoomClose("JoinZoomSession",handleJoinZoom);
            
          }



    },[handleCreateZoom, handleJoinZoom, handleleavemeeting, props, props.zoomClose, props.zoomEstablish, props.zoomFunc])
   

    return (
        <>
         <div onClick={unityCtrlOn} id="meetingSDKElement">
       </div>
        </>
    )
}

export default ZoomMeeting;